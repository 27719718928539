
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
// import { useQuery } from '@urql/vue';
import { Manga, Chapter } from '@/types'

export default defineComponent({
  name: 'Home',
  components: {
    NavBar
  },
  data() {
    return {
      data: [] as { manga: Manga; chapters: Chapter[]; }[],
      loading: true
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    /* Fetch data from the api */
    async getdata(callback?: () => void) {
      this.loading = true
      const data = {
        chapters: (await this.axios.get('/api/latest.json')).data
      }
      this.data = this.groupData(data.chapters)
      /** onyl call if defined */
      callback && callback()
      this.loading = false
    },

    /** group the data to be used in the home */
    groupData(data: Chapter[]) {
      let res: {manga: Manga, chapters: Chapter[]}[] = []
      data.forEach(ch => {
        let index = -1;
        let i = 0;
        res.forEach(test => {
          if(test.manga.id === ch.manga.id) {
            index = i;
          }
          i++
        })
        if(index === -1) {
          res.push({manga: ch.manga, chapters: [ch]})
        } else {
          res[index].chapters.push(ch)
        }
      })
      return res
    }
  }
})
